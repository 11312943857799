import React from 'react';
import Homehero from '../components/Homehero';
import Innovsolutions from '../components/Innovsolutions';
import Webuild from '../components/Webuild';

function Home() {
  return (
    <div>
      <Homehero />
      <Webuild />
      <Innovsolutions />
    </div>
    
  );
}
export default Home;
