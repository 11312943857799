import React from 'react';
import { Link } from 'react-router-dom';

function Innovsolutions() {
  return (
    <div className='innovSolutions'>
      <img src='./assets/bg-curve-shape.svg'
        alt=''
        className='bgCurveShape'
      />
      <div className='container-fluid'>
        <div className='col'>
          <div className='col text-center mb-3'>
            <span>
              <img src='./assets/yellow_arrow_bullet.png'
                className='yellowArrowBullet'
                alt=''
              />
            </span>
            <span className='titleText'
              data-testid='Innovative Solutions title text'>
                INNOVATIVE
            <span className='font-weight-bold'>
                &nbsp;SOLUTIONS</span>
            </span>
          </div>
          <div className='row'>
            <div className='col d-flex justify-content-center'>
              <div className='card border-0 bg-transparent mb-3'>
                <div className='card-header bg-transparent border-0'>
                  <img src='./assets/softdev_card_img.png'
                    alt=''
                    className='cardImg'
                    data-testid='SoftDev Img'
                  />
                </div>
                  <div className='card-body'>
                    <h5 className='card-title'>Software Development</h5>
                    <p className='card-text'
                      data-testid='SoftDev Content Text'
                    >Our field experts provide services
                      in delivering working software, innovation projects,
                      and work on advanced technologies & platforms.
                    </p>
                    <Link to='/softdev'
                      className='btn btn-primary mt-auto'
                      data-testid='SoftDev Link Button'
                    >
                      VIEW CAPABILITIES
                    </Link>
                  </div>
              </div>
            </div>
            <div className='col d-flex justify-content-center'>
              <div className='card border-0 bg-transparent mb-3'>
                <div className='card-header bg-transparent border-0'>
                  <img src='./assets/healthcare_card_img.png'
                    alt=''
                    className='cardImg'
                    data-testid='Healthcare Img'
                  />
                </div>
                  <div className='card-body'>
                    <h5 className='card-title'>Healthcare Staffing</h5>
                    <p className='card-text'
                      data-testid='Healthcare Content Text'>
                        Unique partnerships provide over 100,000 highly
                        skilled medical professionals dedicated to
                        providing exceptional care.
                    </p>
                    <Link to='/healthcare'
                      className='btn btn-primary'
                      data-testid='Healthcare Link Button'
                    >
                      VIEW CAPABILITIES
                    </Link>
                  </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col d-flex justify-content-center'>
              <div className='card border-0 bg-transparent mb-3'>
                <div className='card-header bg-transparent border-0'>
                  <img src='./assets/agile_card_img.png'
                    alt=''
                    className='cardImg'
                    data-testid='Training Img'
                  />
                </div>
                  <div className='card-body'>
                    <h5 className='card-title'>Agile &amp; DevSecOps Training</h5>
                    <p className='card-text'
                      data-testid='Training Content Text'
                    >Foster an Agile mindset by learning Agile principles
                    and methods to lead self-managed cross-collaborative
                    teams to achieve business goals.
                    </p>
                    <Link to='/training'
                      className='btn btn-primary'
                      data-testid='Training Link Button'
                    >
                      VIEW CAPABILITIES
                    </Link>
                  </div>
              </div>
            </div>
            <div className='col d-flex justify-content-center'>
              <div className='card border-0 bg-transparent mb-3'>
                <div className='card-header bg-transparent border-0'>
                  <img src='./assets/transform_card_img.png'
                    alt=''
                    className='cardImg'
                    data-testid='Transform Img'
                  />
                </div>
                  <div className='card-body'>
                    <h5 className='card-title'>Agile Transformation</h5>
                    <p className='card-text'
                      data-testid='Transform Content Text'
                    >Learn how to improve and continuously adapt
                      your companies strategies, plans, and outcomes.
                    </p>
                    <Link to='/transformation'
                      className='btn btn-primary'
                      data-testid='Transform Link Button'
                    >
                      VIEW CAPABILITIES
                    </Link>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src='./assets/bg-curve-shape-grey.svg'
        alt=''
        className='bgCurveShape'
      />
    </div>
  );
}
export default Innovsolutions;
