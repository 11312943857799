import React from 'react';

function TransformHero() {
  return (
    <div>
      <img src='./assets/transform_hero.png'
        alt='Transformation Team Working'
        data-testid='Transform Hero Image'
        className='heroImage'
      />
    </div>
  );
}

export default TransformHero;