import React from 'react';

function Contacthero() {
  return (
    <div>
      <img src='./assets/contact_hero.png'
        alt=''
        data-testid='Contact Hero Image'
        className='heroImage mb-5'
      />
    </div>
  );
}

export default Contacthero;