import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-light bg-light'>
        <Link className='navbar-brand' to='/'>
          <img src='./assets/perfectus_brand_logo.svg'
          alt='Perfectus Solutions'
          data-testid='Perfectus Brand Logo'
          className='brandLogo'
            />
        </Link>
        <button className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
        <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse float-right' id='navbarSupportedContent'>
          <ul className='navbar-nav'>
            <li className='nav-item dropdown'>
              <Link className='nav-link dropdown-toggle'
              to='#'
              id='navbarDropdown'
              role='button'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
              data-testid='Solutions drop down menu'
              >SOLUTIONS
              </Link>
              <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <Link className='dropdown-item'
                    to='/softdev'
                    data-testid='Softdev Link'
                >Software Development
                </Link>
                <Link className='dropdown-item'
                    to='/healthcare'
                    data-testid='Healthcare Link'>
                    Healthcare Staffing
                </Link>
                <Link className='dropdown-item'
                    to='/training'
                    data-testid='Training Link'
                >Agile &amp; DevSecOps Training
                </Link>
                <Link className='dropdown-item'
                    to='/transform'
                    data-testid='Transform Link'
                >Agile Transformations
                </Link>
              </div>
            </li>
            <li className='nav-item'>
              <Link className='nav-link'
                to='/contact'
                data-testid='Contact Link'
              >CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
export default Navbar;
