import React from 'react';

function Footer() {
  return (
    <div data-testid='Footer Text Info' className='footer text-white
      d-flex justify-content-center'>
      <div className='row col-md-10 col-sm-12'>
        <div className='col'>
          <h1 className='font-weight-bold'>210-995-0644</h1>
          <h3>Courtni Gaston</h3>
            <a className='h5'
              href='mailto:cgaston@perfectusolutions.com'>
              cgaston@perfectusolutions.com
            </a>
        </div>
        <div className='col text-center mt-3'>
          <img src='./assets/perfectus_brand_logo_footer.svg'
            alt='Perfectus Solutions'
            data-testid='Perfectus Brand Logo Footer'
            className='brandLogoFooter'
          />
        </div>
      </div>
    </div>
  );
}
export default Footer;
