import React from 'react';

function Webuild() {
  return (
    <div>
      <div className='col mt-3'>
        <div className='col-12 col-xxs-1 text-center mb-3'>
          <span>
            <img src='./assets/yellow_arrow_bullet.png'
              className='yellowArrowBullet'
              alt=''
            />
          </span>
          <span className='titleText'
            data-testid='We build title text'>
              WE BUILD
            <span className='font-weight-bold'>
              &nbsp;OPPORTUNITIES</span>
          </span>
        </div>
      </div>
      <div className='row col text-right mb-4'>
        <div className='col'>
          <img src='./assets/we_build.jpg'
            className='weBuildImg'
            data-testid='Webuild Img'
            alt='Happy Team Members'
          />
        </div>
        <div className='col text-left'
          data-testid='Webuild Text Content'>
          <p> Perfectus Solutions leads the way in secure
            software development and healthcare
            staffing industries.
          </p>
          <p>Our subject matter experts hold highly recognized
            development and engineering certifications.
          </p>
          <p> We can help you:
          </p>
            <ul>
              <li>
                Accelerate Software Delivery
              </li>
            </ul>
            <ul>
              <li>
                Improve Business / IT Alignment and team Morale
              </li>
            </ul>
            <ul>
              <li>
              Adapt to changing market conditions and priorities
              </li>
            </ul>
            <ul>
              <li>
              Reduce project costs
              </li>
            </ul>
        </div>
      </div>
    </div>
  );
}

export default Webuild;