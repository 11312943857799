import React from 'react';
import TrainingBadges from './TrainingBadges';

function TrainingContent() {
  return (
    <div>
      <div className='col greyPatternBackground'>
        <div className='container-fluid'>
          <div className='row justify-content-center text-center'>
            <div className='infoParagraph p-5'>
              Foster an Agile mindset by learning Agile principles
              and methods to lead self-managed cross-collaborative
              teams to achieve business goals.
            </div>
          </div>
        </div>
        <div>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            {/* Cards start here */}
            <div className='card softDevCard'
              data-testid='Government Card'>
              <img className='softDevCardImg' src='./assets/government.jpg'
                alt='Government Building' data-testid='Government Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='Government Title'>GOVERNMENT
                  </h5>
                    <p className='card-text'
                      data-testid='Government Card Text'>GSA Schedule courses
                      and custom courses to assure agencies meet their mission.
                    </p>
                </div>
            </div>
            <div className='card softDevCard'
              data-testid='Corporate Card'>
              <img className='softDevCardImg'
                src='./assets/corporate.jpg'
                alt='Corporate Building'
                data-testid='Corporate Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='Corporate Title'>CORPORATE
                  </h5>
                    <p className='card-text'
                      data-testid='Corporate Text'>
                        Cost per student will be significantly
                        less, when compared to attending separate
                        courses, and the entire team benefits from
                        participating as a group.
                    </p>
                </div>
            </div>
            <div className='card softDevCard'
              data-testid='Individual Card'>
              <img className='softDevCardImg'
                src='./assets/individual.jpg'
                alt='Individual attending online course.'
                data-testid='Individual Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='Individual Title'>INDIVIDUAL
                  </h5>
                    <p className='card-text'
                      data-testid='Individual Text'>
                        Tangible certified courses will increase
                        your knowledge base and propel your career
                        objectives.
                    </p>
                </div>
            </div>
          </div>{/* Cards end here */}
        </div>
      </div>   
    </div>
    <div className='row col greyPatternBackground'>
      <TrainingBadges />
    </div>
    <img src='./assets/bg-curve-shape-grey.svg' alt='' className='bgCurveShape' />
  </div>
  );
}

export default TrainingContent;