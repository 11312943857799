import React from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './Pages/Home';
import Softdev from './Pages/Softdev';
import Contact from './components/Contact';
import Healthcare from './Pages/Healthcare';
import Training from './Pages/Training';
import Transform from './Pages/Transform';
import Contacthero from './components/Contacthero';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
        <Routes>
          <Route path="/" element={ <Home /> } exact />
          <Route path="/softdev" element={ <Softdev /> } exact />
          <Route path="/healthcare" element={ <Healthcare /> } exact />
          <Route path="/training" element={ <Training /> } exact />
          <Route path="/transform" element={ <Transform /> } exact />
          <Route path="/contact" element={ <Contacthero /> } exact />
        </Routes>
      <Contact />
      <Footer />
    </BrowserRouter>
  );
}
export default App;
