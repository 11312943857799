import React from 'react';

function TrainingHero() {
  return (
    <div>
      <img src='./assets/training_hero.png'
        alt='Training Team Working'
        data-testid='Training Hero Image'
        className='heroImage'
      />
    </div>
  );
}

export default TrainingHero;