import React from 'react';

function Softdevhero() {
  return (
    <div>
      <img src='./assets/softdev_hero.jpg'
        alt='Woman working and smiling'
        data-testid='Softdev Hero Image'
        className='heroImage'
      />
    </div>
  );
}

export default Softdevhero;