import React from 'react'
import emailjs from '@emailjs/browser';

function sendEmail(e) {
  e.preventDefault();
  emailjs.sendForm('service_7ivd8yg',
    'template_m9rjxel', e.target,
    'user_MO7LQttZc1xSDnwiLShBM')
    .then(result => {
      console.log(result.text);
      window.location.reload('/home');
    }, error => {
      console.log(error.text);
    });
}

function Contact() {
  return (
    <div>
      <div className='contactTeam'>
        <div className='container-fluid'>
          <div className='col'>
            <div className='col text-center mb-3'>
              <span>
                <img src='./assets/yellow_arrow_bullet.png'
                  className='yellowArrowBullet'
                  alt=''
                />
              </span>
              <span className='titleText'
                data-testid='Contact title text'>
                  CONTACT THE
                <span className='font-weight-bold'>
                  &nbsp;TEAM
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='darkBlueContainer'>
      <div className='row col mb-5'>
        <div className='col-xl-6 col-lg-7 col-md-8 col-12'>
          <div className='mb-3 display-2
            blackText-responsive d-flex justify-content-center'
            data-testid='Callout Text'
          >
            SERVICE.<br />MISSION.<br />INNOVATION.
          </div>
        </div>
        <div className='col'>
          <form onSubmit={sendEmail}>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <label htmlFor='validationCustom01'>* FIRST NAME</label>
                <input label='first_name' type='text' name='first_name'
                  className='form-control'
                  id='validationCustom01'
                  placeholder='Required'
                  data-testid='First Name input'
                  required
                />
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='validationCustom02'>* LAST NAME</label>
                <input type='text' name='last_name'
                  className='form-control'
                  id='validationCustom02'
                  placeholder='Required'
                  data-testid='Last Name input'
                  required />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <label htmlFor='validationCustom03'>* EMAIL ADDRESS</label>
                <input type='text' name='email_address' className='form-control'
                  id='validationCustom03'
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  placeholder='name@domain.com Required'
                  data-testid='Email input'
                  required />
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='validationCustom04'>* PHONE (XXX-XXX-XXXX)</label>
                <input type='text' name='phone' className='form-control'
                  id='validationCustom04'
                  placeholder='Required'
                  pattern='^\d{3}-\d{3}-\d{4}$'
                  data-testid='Phone input'
                  required
                />
              </div>
            </div>
            {/* <ReCAPTCHA
              sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'/> */}
            <button className='btn btn-warning btn-lg mt-3'
              type='submit' value='validate'>CONTACT US</button>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Contact;