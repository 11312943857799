import React from 'react';
import TransformHero from '../components/TransformHero';
import TransformContent from '../components/TransformContent';

function Transform() {
  return (
    <div>
      <TransformHero />
      <TransformContent />
    </div>
    
  );
}
export default Transform;
