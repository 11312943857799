import React from 'react';
import Healthcarehero from '../components/Healthcarehero';
import Medicalservices from '../components/Medicalservices';

function Healthcare() {
  return (
    <div>
      <Healthcarehero />
      <Medicalservices />
    </div>
  );
}

export default Healthcare;