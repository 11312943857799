import React from 'react';

function Homehero() {
  return (
    <div>
      <img src='./assets/home_hero.jpg'
        alt='Woman working and smiling'
        data-testid='Home Page Jumbo Image'
        className='heroImage'
      />
    </div>
  );
}

export default Homehero;