import React from 'react';

function TrainingBadges() {
  return (
    <div className='container-fluid d-flex justify-content-center
      pb-5 pt-3'>
      <div className='row col-10'>
        <div className='col text-center'>
          <img src='./assets/safe.svg'
            alt='Scaled Agile Framework for Enterprise'
            data-testid='safeLogo'
            className='safeLogo'/>
        </div>
        <div className='col text-center'>
          <img src='./assets/icagile_brand.svg'
            alt='Internatioal Consortium for Agile'
            data-testid='icAgileLogo'
            className='icAgileLogo'/>
        </div>
        <div className='col text-center'>
          <img src='./assets/Scrum Alliance.svg'
            alt='Scrum Alliance'
            data-testid='scrumAllianceLogo'
            className='scrumAllianceLogo'/>
        </div>
        <div className='col text-center'>
          <img src='./assets/devops_institute.png'
            alt='DevOps Institute'
            data-testid='devopsInstituteLogo'
            className='devopsInstituteLogo'/>
        </div>
        <div className='col text-center'>
          <img src='./assets/logo-4.png'
            alt='Kanban University'
            data-testid='kanbanUniveristyLogo'
            className='kanbanUniveristyLogo'/>
        </div>
      </div>
    </div>
  );
}

export default TrainingBadges;
