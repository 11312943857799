import React from 'react';
import Softdevhero from '../components/Softdevhero';
import Moderncapa from '../components/Moderncapa';

function Softdev() {
  return (
    <div>
      <Softdevhero />
      <Moderncapa />
    </div>
    
  );
}
export default Softdev;
