import React from 'react';
// import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

function Moderncapa() {
  return (
    <div>
      <div className='col mt-5 pt-3 mb-2'>
        <div className='col-12 col-xxs-1 text-center'>
          <span>
            <img src='./assets/yellow_arrow_bullet.png'
              className='yellowArrowBullet'
              alt=''
            />
          </span>
          <span className='titleText'
            data-testid='Modern Capa text'>
              MODERN
            <span className='font-weight-bold'>
              &nbsp;CAPABILITIES</span>
          </span>
        </div>
      </div>
      <div className='mb-5'>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            {/* Cards start here */}
            <div className='card softDevCard'
              data-testid='Kubernetes Card'>
              <img className='softDevCardImg' src='./assets/kubernetes.svg'
                alt='Kubernetes' data-testid='Kubernetes Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='Kubernetes Title'>KUBERNETES CERTIFIED
                  </h5>
                    <p className='card-text'
                      data-testid='Kubernetes Text'>Automate computer<br/>application
                      deployment with<br/>confidence by migrating to<br/>Kubernetes.
                    </p>
                </div>
            </div>
            <div className='card softDevCard'
              data-testid='Infrastructure Card'>
              <img className='softDevCardImg'
                src='./assets/infrastructure.png'
                alt='Computer Code'
                data-testid='Infrastructure Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='Infrastructure Title'>INFRASTRUCTURE-AS-CODE
                      &amp; CONFIGRATION-AS-CODE
                  </h5>
                    <p className='card-text'
                      data-testid='Infrastructure Text'>Our services provide you
                      the ease in managing all your servers and configuration.
                      Tools like Terraform, Ansible, Puppet, Chef and more,
                      can transform your infrastructure through automation
                      and continuous delivery.
                    </p>
                </div>
            </div>
            <div className='card softDevCard'
              data-testid='CI/CD Card'>
              <img className='softDevCardImg'
                src='./assets/ci_cd.png'
                alt='Computer Code'
                data-testid='CI/CD Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='CI/CD Title'>CONTINUOUS INTEGRATION/
                    CONTINUOUS DEPLOYMENT
                  </h5>
                    <p className='card-text'
                      data-testid='CI/CD Text'>Ship software quickly
                      and efficiently, getting products to market faster
                      than ever before by using Continuous
                      Integration/Continuous Deployment.
                    </p>
                </div>
            </div>
            <div className='card softDevCard'
              data-testid='Serverless Card'>
              <img className='softDevCardImg'
                src='./assets/ci_cd.png'
                alt='Serverless Computing'
                data-testid='Serverless Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='Serverless Title'>SERVERLESS ARCHITECTURES
                  </h5>
                    <p className='card-text'
                      data-testid='Serverless Text'>Build and run applications
                      and services without having to manage infrastructure.
                    </p>
                </div>
            </div>
            <div className='card softDevCard'
              data-testid='Serverless Card'>
              <img className='softDevCardImg'
                src='./assets/cloud_onprem.png'
                alt='Cloud Computing'
                data-testid='Cloud Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='Cloud Title'>CLOUD, ON-PREM,
                    AND HYBRID INFRASTRUCTURE
                  </h5>
                    <p className='card-text'
                      data-testid='Cloud Text'>With strong capabilities
                        in on-prem, cloud and hybrid infrastructure,
                        we are here to help your organization.
                        (AWS, Azure, GCP, VMWare)
                    </p>
                </div>
            </div>
            <div className='card softDevCard'
              data-testid='Serverless Card'>
              <img className='softDevCardImg'
                src='./assets/cyber.png'
                alt='Cyber Technology'
                data-testid='Cyber Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='Cyber Title'>CYBER
                  </h5>
                    <p className='card-text'
                      data-testid='Cyber Text'>Protect your data and your
                        reputation with state-of-the-art cyber solutions to
                        proactively defend your organization. We focus on
                        reducing, securing, and maintaining valuable assets.
                    </p>
                </div>
            </div>
            {/* Cards end here */}
          </div>
        </div>
      </div>
      <div data-testid='Need Training Text'>
        <div className='needTraining'>
          <img src='./assets/bg-curve-shape.svg'
            alt=''
            className='bgCurveShape'/>
            <div className='blackText-responsive-center pt-5'>
              Need DevSecOps<br/>and/or Agile Training?<br />
              <Link to='/training'
                className='btn btn-primary btn-lg mt-5'
                data-testid='Need Training button'
              >
                LEARN MORE
              </Link>
            </div>  
          <img src='./assets/bg-curve-shape-grey.svg'
            alt=''
            className='bgCurveShape'/>
        </div>
      </div>
    </div>
  );
}

export default Moderncapa;