import React from 'react';

function Healthcarehero() {
  return (
    <div>
      <img src='./assets/healthcare_hero.jpg'
        alt='Healthcare worker'
        data-testid='Healthcare Hero Image'
        className='heroImage'
      />
    </div>
  );
}

export default Healthcarehero;