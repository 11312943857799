import React from 'react';

function TransformContent() {
  return (
    <div>
      <div className='col greyPatternBackground'>
        <div className='pt-5'>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            {/* Cards start here */}
            <div className='card softDevCard'
              data-testid='AdaptThrive Card'
            >
              <img className='softDevCardImg'
                src='./assets/adapt_thrive.svg'
                alt=''
                data-testid='AdaptThrive Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='AdaptThrive Title'>
                      Adapt & Thrive in a Constantly Shifting Market
                  </h5>
                    <p className='card-text'
                      data-testid='AdaptThrive Card Text'>
                        Cultivate an environment that encourages creativity,
                        flexibility and fluency, Agile processes harness change
                        for the customer's competitive advantage.
                    </p>
                </div>
            </div>
            <div className='card softDevCard'
              data-testid='Communication Card'>
              <img className='softDevCardImg'
                src='./assets/communication.svg'
                alt=''
                data-testid='Communication Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='Communication Title'>
                      Better Communication Equals Customer Satisfaction
                  </h5>
                    <p className='card-text'
                      data-testid='Communication Text'>
                        Communication with your customer will improve
                        when you invite them to collaborate. Learn to
                        deliver improved customer satisfaction and
                        customer-focused outcomes.
                    </p>
                </div>
            </div>
            <div className='card softDevCard'
              data-testid='CLI Card'>
              <img className='softDevCardImg'
                src='./assets/continuous_learning.svg'
                alt=''
                data-testid='CLI Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='CLI Title'>
                      Continuous Learning and Improvement
                  </h5>
                    <p className='card-text'
                      data-testid='CLI Text'>
                        The Agile mindset and roadmap will allow
                        for a continuous learning environment
                        and your teams will experience continuous
                        improvement in products.
                    </p>
                </div>
            </div>
            <div className='card softDevCard'
              data-testid='Leaders Card'>
              <img className='softDevCardImg'
                src='./assets/leadership.svg'
                alt=''
                data-testid='Leaders Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='Leaders Title'>
                      Build Stronger Leaders
                  </h5>
                    <p className='card-text'
                      data-testid='Leaders Text'>
                        Adopt the Agile mindset to build stronger
                        leaders who empower their teams to create
                        innovation that take your products and
                        services to a higher level.
                    </p>
                </div>
            </div>
            <div className='card softDevCard'
              data-testid='Productivity Card'>
              <img className='softDevCardImg'
                src='./assets/productivity.svg'
                alt=''
                data-testid='Productivity Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='Productivity Title'>
                      Increase Productivity and Decrease Risks
                  </h5>
                    <p className='card-text'
                      data-testid='Productivity Text'>
                        Integrate Agile principles and help
                        teams complete software development
                        projects on a faster cadence,
                        delivering incrementally for
                        quick customer feedback and
                        increased productivity.
                    </p>
                </div>
            </div>
            <div className='card softDevCard'
              data-testid='Satisfaction Card'>
              <img className='softDevCardImg'
                src='./assets/satisfaction.svg'
                alt=''
                data-testid='Satisfaction Img'
              />
                <div className='card-body'>
                  <h5 className='card-title'
                    data-testid='Satisfaction Title'>
                      Dramatically Improve Job & Team Satisfaction
                  </h5>
                    <p className='card-text'
                      data-testid='Satisfaction Text'>
                        Happy employees mean improved performance,
                        greater energy, better health and higher
                        retention rates. Deliver value to your
                        customer faster while increasing employee
                        satisfaction.
                    </p>
                </div>
            </div>
          </div>{/* Cards end here */}
        </div>
      </div>   
    </div>
    <div className='row col greyPatternBackground'>
    </div>
    <img src='./assets/bg-curve-shape-grey.svg' alt='' className='bgCurveShape' />
  </div>
  );
}

export default TransformContent;