import React from 'react';
import TrainingContent from '../components/TrainingContent';
import TrainingHero from '../components/TrainingHero';

function Training() {
  return (
    <div>
      <TrainingHero />
      <TrainingContent />
    </div>
    
  );
}
export default Training;
