import React from 'react'

function Medicalservices() {
  return (
    <div>
      <div className='col greyPatternBackground'>
        <div className='col-12 col-xxs-1 text-center pt-5 pb-5'>
          <span>
            <img src='./assets/yellow_arrow_bullet.png'
              className='yellowArrowBullet'
              alt=''
            />
          </span>
          <span className='titleText'
            data-testid='Medical Services title'>
              MEDICAL
            <span className='font-weight-bold'>
              &nbsp;SERVICES</span>
          </span>
        </div>
        <div>
          <div className='container-fluid'>
            <div className='row justify-content-center'>
              {/* Medical Services Cards */}
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>SKILLED NURSING</h5>
                      <p className='card-text'>Providing a broad range of skilled
                        nursing such as Nursing Aides and Nurse Practitioners
                        providing inpatient and outpatient care.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>LAB SERVICES</h5>
                      <p className='card-text'>Providing services for testing
                        specimens from the body that are used to diagnose
                        and treat patients.<br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>CLINICAL PSYCHOLOGIST</h5>
                      <p className='card-text'>Providing comprehensive mental and
                        behavioral care for individuals and families.<br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>CLINICAL PHARMACIST</h5>
                      <p className='card-text'>Providing direct patient care that
                        optimizes the use of medication to provide health,
                        wellness & disease prevention.<br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>PEBLO TECHS</h5>
                      <p className='card-text'>Providing information, assistance,
                      and case status updates to the affected Service member
                      throughout the Disability Evaluation System process.
                      <br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>LAB TECHNICIANS</h5>
                      <p className='card-text'>Providing services for recording data,
                        protecting the accuracy and efficiency of scientific
                        experiments, maintaining equipment and lab tools and
                        keeping the lab organized.
                      <br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>SOCIAL SERVICES</h5>
                      <p className='card-text'>Providing services to protect
                        vulnerable children and support families in need of
                        assistance.
                      <br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>PATIENT SITTERS</h5>
                      <p className='card-text'>Providing comfort for patients
                        and looking out for their safety and ensuring they're
                        satisfied with the level of care they're receiving.
                      <br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>OPTHAMOLOGY</h5>
                      <p className='card-text'>Providing services for diagnosing
                        and treating all eye diseases, including eye surgery,
                        exams and prescriptions for eyeglasses and contact lenses.
                      <br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>INFORMATICS</h5>
                      <p className='card-text'>Health informatics professionals
                        use their knowledge of healthcare, information systems,
                        databases and information technology security to gather,
                        store, interpret and manage the massive amount of data
                        generated when care is provided to patients.
                      <br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>FACILITY MANAGEMENT</h5>
                      <p className='card-text'>Providing services to oversee financial,
                        technical and administrative operations within a health care
                        organization. They must plan, organize, implement, evaluate
                        and monitor all facility programs and departments.
                      <br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>BIO-TECH MEDICAL</h5>
                      <p className='card-text'>Providing maintenance to service
                        medical equipment and work on diagnostic and treatment
                        devices, such as CAT scanners and heart defibrillators.
                      <br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>MEDICAL RECORDS TECHNICIANS</h5>
                      <p className='card-text'>Ensuring the accurate management, organization,
                        and transcription of patient&apos;s medical histories, symptoms, diagnoses,
                        and treatments using the customer's preferred medical records systems.
                      <br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>MEDICAL LOGISTIC TECHNICIANS</h5>
                      <p className='card-text'>Providing warehouse management of medical supply
                        inventory and coordination of delivery and maintenance.
                      <br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-transparent border-0 medicalServicesCard'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='yellowVertStrip'>
                    </div>
                    <div className='row col pl-4'>
                      <h5 className='card-title health-title'>OPTOMETRY TECHNICIANS</h5>
                      <p className='card-text'>Assisting and working closely with
                        optometrists, and perform the administrative duties
                        and assist with vision acuity testing.
                      <br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Medical Services Cards */}
            </div>
          </div>
        </div>
      </div>
      <img src='./assets/bg-curve-shape-grey.svg'
        alt=''
        className='bgCurveShape'
      />
    </div>
  )
}

export default Medicalservices
